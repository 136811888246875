// 
// Page-title
// 

.page-title-box {
    padding-bottom: 6px;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px !important;
    }
}